import { h, Component } from 'preact'
import { Router, route } from 'preact-router'

import environment from '../environments/environment'

import Home from './routes/home'
import Error from './routes/error'
import Info from './routes/info'

import axios from 'axios'

// eslint-disable-next-line no-unused-vars
import style from './style.less'

export default class App extends Component {
	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = (e) => {
		this.currentUrl = e.url
	}

	state = {
		linkId: '',
		data: null,
	}

	setUpAuthorization() {
		try {
			new URL(window.location.href)
		} catch (e) {
			route(
				`/error?err=${encodeURI(
					'failed to create url from window.location.href'
				)}&status=0`
			)
			return
		}

		const url = new URL(window.location.href)
		if (url.pathname === '/') {
			route('/')
			return
		}

		let aad = url.searchParams.get('aad')
		if (aad) {
			this.setState({ linkId: decodeURIComponent(aad) })
		} else {
			let linkId = url.pathname.substring(url.pathname.lastIndexOf('/') + 1)

			if (linkId === 'error') {
				return
			}

			this.setState({ linkId })
			if (!linkId) {
				route(`/error?err=${encodeURI('no id present')}&status=0`)
				return
			}
		}
	}

	componentDidMount() {
		this.setUpAuthorization()
		setInterval(() => {
			this.getData()
		}, 21600000)
	}

	getData() {
		axios.get(`${environment.apiBasePath}/vehicle/${this.state.linkId}`).then(
			(response) => {
				const data = response.data
				this.setState({
					data,
				})

				if (data.design === 2) {
					document.body.classList.add('dark-theme')
				}

				let analytics = (window.analytics = window.analytics || undefined)
				if (analytics) {
					analytics.identify({
						partnerId: data.supplier_partner_id,
						orderId: data.production_order_id,
					})
				}
			},
			(error) => {
				if (error.response) {
					console.log(error.response.data)
					console.log(error.response.status)
					console.log(error.response.headers)
					route(
						`/error?err=${encodeURI(error.response.data)}&status=${
							error.response.status
						}`
					)
				} else {
					console.log(error)
					route(`/error?err=${encodeURI('failed get request')}&status=0`)
				}
			}
		)
	}

	componentDidUpdate() {
		if (this.state.data === null) {
			if (this.state.linkId) {
				console.log(
					`call get on '${environment.apiBasePath}/vehicle/${this.state.linkId}'`
				)
				this.getData()
			}
		}
	}

	render() {
		return (
			<div
				style={{
					// width: '100vw',
					// height: '100vh',
					height: '100%',
				}}
				app-root
			>
				<Router onChange={this.handleRoute}>
					<Error path="/error" />
					<Home default data={this.state.data} />
					<Info path="/" />
				</Router>
			</div>
		)
	}
}
