import 'url-polyfill'
import 'promise-polyfill/src/polyfill'

import { h, render } from 'preact'

let root
function init() {
	let App = require('./app').default
	root = render(<App />, document.body, root)
}

// dynamically create manifest
function addDynamicManifest() {
	let dynamicManifest = {
		name: 'Car-Live-Tracking',
		short_name: 'Car-Live-Tracking',
		start_url: window.location.href,
		display: 'minimal-ui',
		orientation: 'portrait',
		background_color: '#ffffff',
		theme_color: '#1f3347',
		icons: [
			{
				src: `${window.location.origin}/assets/icons/wagen-192.png`,
				type: 'image/png',
				sizes: '192x192',
				purpose: 'any',
			},
			{
				src: `${window.location.origin}/assets/icons/wagen-192-maskable.png`,
				type: 'image/png',
				sizes: '192x192',
				purpose: 'maskable',
			},
			{
				src: `${window.location.origin}/assets/icons/wagen-512.png`,
				type: 'image/png',
				sizes: '512x512',
				purpose: 'any',
			},
			{
				src: `${window.location.origin}/assets/icons/wagen-512-maskable.png`,
				type: 'image/png',
				sizes: '512x512',
				purpose: 'maskable',
			},
			{
				src: `${window.location.origin}/assets/icons/wagen-768.png`,
				type: 'image/png',
				sizes: '768x768',
				purpose: 'any',
			},
			{
				src: `${window.location.origin}/assets/icons/wagen-768-maskable.png`,
				type: 'image/png',
				sizes: '768x768',
				purpose: 'maskable',
			},
			{
				src: `${window.location.origin}/assets/icons/wagen-1024.png`,
				type: 'image/png',
				sizes: '1024x1024',
				purpose: 'any',
			},
			{
				src: `${window.location.origin}/assets/icons/wagen-1024-maskable.png`,
				type: 'image/png',
				sizes: '1024x1024',
				purpose: 'maskable',
			},
		],
	}

	const stringManifest = JSON.stringify(dynamicManifest)
	let link = document.querySelector('#manifest-placeholder')

	// BLOB variant
	const blob = new Blob([stringManifest], { type: 'application/json' })
	const manifestURL = URL.createObjectURL(blob)
	link.setAttribute('href', manifestURL)

	console.log(
		'Dynamic manifest added to document, start_url:',
		dynamicManifest.start_url
	)
}

// start PWA service worker, it is required for A2HS functionality
if ('serviceWorker' in navigator) {
	navigator.serviceWorker.register('/assets/js/a2hs-sw.js').then(() => {
		console.log('A2HS Service Worker Registered')
	})
}

// register ServiceWorker via OfflinePlugin, for prod only:
if (process.env.NODE_ENV === 'production') {
	require('./segment')
}

// in development, set up HMR:
if (module.hot && process.env.NODE_ENV !== 'production') {
	//require('preact/devtools');   // turn this on if you want to enable React DevTools!
	module.hot.accept('./app', () => requestAnimationFrame(init))
}

init()
addDynamicManifest()
